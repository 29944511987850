@import url("https://fonts.googleapis.com/css?family=Lato:400,500,600,700&display=swap");
 body {
     font-family: "Poppins", sans-serif;
     font-size: 14px;
     color: #222;
     padding: 0px;
}
 * {
     box-sizing: border-box;
}
 * ::before, * ::after {
     box-sizing: border-box;
}
 .main {
     width: 100% !important;
     height: 100% !important;
     background: white;
     border-radius: 20px;
     padding: 30px;
     position: relative;
     display: flex;
      align-items: center;
     justify-content: center;
}
@media screen and (min-width:0px) and (max-width:450px) {
    .main {
        padding: 5px !important; 
    }
    .modal_body{
        padding: 5px !important;
    }
    .modal_grid{
        position: relative;
        width: 95%;
        padding-right: 5px !important;
        padding-left: 5px !important;
    }
}

 .main .form_wrapper {
     width: 100% !important;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
     position: relative;
     overflow: hidden;
     padding: 0px 5px 0px 22px;
}
 .main .form_wrapper .tile {
     text-align: center;
     width: 100% !important;
     overflow: hidden;
     /* height:  50px!important; */
}
 .main .form_wrapper .tile h3 {
     font-size: 29px;
     margin: 0px;
     font-weight: 600;
     transition: all 0.3s ease;
}
 .main .form_wrapper .radio {
     display: none;
}
 .main .form_wrapper .tab {
     /* width: 47%; */
     margin-right: 10px !important;
     border: solid 2px #F1F1F1;
     height: 56px;
     border-radius: 5px;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 18px !important;
     font-weight: 700;
     transition: color 0.3s ease;
     user-select: none;
     padding: 0px 50px;
     box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
}
.main .form_wrapper .tab1 {
       margin-right: 0px !important;
   
}
 .main .form_wrapper .tab.login_tab {
     
     /* border-top-left-radius: 0px;
     border-bottom-left-radius: 0px; */
     margin: 10px 0px;
     color: #64729dcc;
     border-radius: 15px;
     /* width: 48%; */
}
 .main .form_wrapper .tab.signup_tab {
     border-left: none;
     border-top-left-radius: 0px;
     border-bottom-left-radius: 0px;
     margin: 10px 0px;
}

.tab2{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-width: 0px !important;
    /* border-bottom-width: 0px; */
    display: flex !important;
    justify-content: space-between !important;
}
.tab3{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-width: 0px !important;
    /* border-bottom-width: 0px; */
    display: flex !important;
    justify-content: space-between !important;
}

@media screen and (max-width:426px) {
   .tab2
    {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      
    } 
    .tab3
    {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      
    } 

 
}

.main .form_wrapper .tab1 {
    /* width: 47%; */
    /* margin-right: 10px !important; */
    
    /* margin-right: 10px !important; */
    border: solid 2px #F1F1F1;
    height: 56px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px !important;
    font-weight: 700;
    transition: color 0.3s ease;
    -webkit-user-select: none;
    user-select: none;
    padding: 0px 30px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
}
.main .form_wrapper .tab.login_tab1 {
    
    /* border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; */
    margin: 10px 0px;
    color: #f1f1f1;
    border-radius: 15px;
}
.main .form_wrapper .tab.signup_tab1 {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 10px 0px;
}


 .main .form_wrapper .shape:hover {
     background: linear-gradient(-45deg, #311453, #270D44) no-repeat center;
}


 .main .form_wrapper #login:checked ~ .form_wrap {
     transform: translateX(0);
}


    .active-tab {
      
     color: #270d44 !important;
     /* background-color: #270D44; */
    }

 .main .form_wrapper #signup:checked ~ .tile .login {
     display: none;
}
 .main .form_wrapper #signup:checked ~ .form_wrap {
     
}

 .main .form_wrapper a {
    color: #5319a8;
    text-decoration: none;
    transition: all 0.3s linear;
    /* border-radius: 4px; */
    font-weight: 500;
    text-align: center;
    font-weight: 900;
    font-size: 15px;
    border: none;
}
 .main .form_wrapper a:hover {
     color: #311453;
}
 .main .form_wrapper .form_wrap {
     display: flex;
     width: 100%;
     flex: 0 0 100%;
     transition: transform 0.3s ease, opacity 0.2s ease;
     padding-top: 6px;
}
 .main .form_wrapper .form_wrap .form_fild {
     width: 100%;
     flex: 0 0 100%;
     transition: all 0.5s ease;
}
 .main .form_wrapper .form_wrap .form_fild .input_group {
     width: auto;
     /* margin: 13px 0px; */
    
}
 .main .form_wrapper .form_wrap .form_fild .input_group .input {
     border: solid #F1F1F1 2px;
     border-radius: 5px;
     width: auto;
     height: 40px;
     padding: 25px 55px;
     font-size: 15px;
     font-weight: 500;
     outline: none;
     transition: all 0.3s linear;
}
 .main .form_wrapper .form_wrap .form_fild .input_group .input::placeholder {
     color: #ADADAD;
}

 .main .form_wrapper .form_wrap .form_fild .forgot {
     display: block;
     margin-bottom: 15px;
     padding: 0px 2px;
     text-align: right;
}
 .main .form_wrapper .form_wrap .form_fild .btn {
    
     height: 50px;
     padding: 0px 60px;
     margin: 15px 0px;
     border: none;
     outline: none;
     font-size: 18px;
     font-weight: 600;
     letter-spacing: 0.8px;
     color: white;
     background: linear-gradient(45deg, #311453, #270D44) no-repeat center;
     border-radius: 15px;
     /* display: flex;
     align-items: center;
     justify-content: center; */
     transition: all 0.3s linear;
     margin-left: auto;
     margin-right: auto;
     width: 100%;
}
 .main .form_wrapper .form_wrap .form_fild .btn:hover {
     background: linear-gradient(-45deg, #402361, #270D44) no-repeat center;
}
 .main .form_wrapper .form_wrap .form_fild .btn:active {
     transform: scale(0.95);
}
 .main .form_wrapper .form_wrap .form_fild .not_mem {
    text-align: center;
    /* background-color: crimson; */
    /* border: 2px solid #270d44; */
    width: fit-content;
    margin: auto;
    padding:10px 25px;
    align-items: center;
    border-radius: 5px;
}
.main .form_wrapper .form_wrap .form_fild .not_mem:hover{
    /* background-color: #270D44; */
   
}
.main .form_wrapper .form_wrap .form_fild .not_mem:hover a label{
    /* color: #ffffff !important; */
}
.already{
    text-align: center;
}
.already label
{
    font-size: 12px !important;
    font-weight: 500;
}
.already span{
    font-weight: 700;
    cursor: pointer;
    color: #270D44;
    font-size: 14px;
}
 .main .form_wrapper .form_wrap .form_fild .not_mem label {
     margin-bottom: 0px !important;
     pointer-events: none;
}
 .main .form_wrapper .form_wrap .form_fild .not_mem label span {
    pointer-events: all;
    color: #270D44;
    text-decoration: none;
    transition: all 0.3s linear;
    font-weight: 700;
    font-family: Lato,sans-serif;
    display: inline !important;
    border: none !important;
    padding-left: 5px;
}
 .main .form_wrapper .form_wrap .form_fild .not_mem label span:hover {
     color: #311453;
}
.btn{
    box-shadow: none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.login_otp{
    padding: 10px 25px;
    /* background-color: #ADADAD; */
    border: 2px solid #270D44;
}
.login_otp:hover{
/* background-color: #270D44;
color: #ffffff !important; */
}
.forgot_pass{
    font-size: 13px;
    font-weight: 300;
    text-align: right;
}
.forgot .forgot_pass
{
    text-align: right;
}

.verfy-special-btn{
    position: absolute;
    margin: 22px 13px !important;
    top: 0;
    right: 0;
    height: 40px !important;
    padding: 0 !important;
    width: 100px !important;
}

@media screen and (min-width:0px) and (max-width:450px) {
    .verfy-special-btn{
        width: 75px !important;
        margin: 22px 8px !important;
    } 
}

label.login_otp{
    color: #270D44 !important;
    border-radius: 3px;
}

.signin_signup{
    margin-top: 160px!important;
    margin-bottom: 50px;
    padding: 0px !important;
    /* box-shadow: 0 0 8px rgb(0 0 0 / 25%); */
}

@media screen and (max-width:576px) {
    .signin_signup{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        overflow-x: hidden;
    }
}

.modal_close{
    position: fixed;
    top: 90px;
    right: 15px;
    background-color: #270D44 !important;
    border: 0;
   
    padding: 10px 15px;
    border-radius: 50%;
    color: #fff !important;
    -webkit-appearance: none;
    outline: none !important;
    font-weight: 700;
    line-height: 1;
    z-index: 999;
    font-size: 35px;
    text-shadow: 0 1px 0 #fff;
    opacity: 1 !important;
  }

  .signup_image{
    width: 100%;  
    vertical-align: middle;
    align-items: center;
    /* margin-top: 20%;     */
  }

  @media screen and (min-width:0px) and (max-width:992px) {
    .signup_image{
       display: none;
    } 
  }
  @media screen and (min-width:992px){
    .signup_image{
        display: block;
     } 
  }


.input_email{
    position: absolute;
    top: 27px;
    left: 12px;
    color: #270D44;
    font-size: 20px;
}

.input_group{
    position: relative !important;
}

.password_hide{
    position: absolute;
    top: 37px;
    right: 12px;
    color: #270D44;
    font-size: 20px;
}

.password_hide1{
    position: absolute;
    top: 8px;
    right: 12px;
    color: #270D44;
    font-size: 20px;
}
.password_change{
    position: absolute;
    top: 60px;
    right: 30px;
    color: #270D44;
    font-size: 20px;
}
.main .form_wrapper .form_wrap .form_fild .input_group span.password_hide{
    /* opacity: 0.5 ; */
}

#login_tab{
    /* border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important; */
    /* color: #f1f1f1 !important; */
}



#sign_tab{
    border-left: none;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.full_img{
    height: 100%;
    /* z-index: -9999999; */
    opacity: 0.999;
}

.ant-upload-list-item-card-actions{
    display: none !important;
}

.signin_signup{
    margin-top: 160px!important;
    margin-bottom: 50px;
    padding: 0px !important;
    /* box-shadow: 0 0 8px rgb(0 0 0 / 25%); */
}
.modal_grid
{
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    border-radius: 20px;
}
.signup_ip
{
    padding: 10px;
    border-color: #2C0264;
    border-radius: 10px;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    margin-right: 3px;
}

@media screen and (max-width:400px) {
    .signup_wa
    {
        font-size: 11px !important;
    }
}
@media screen and (max-width:400px) {
.main .form_wrapper .tab.login_tab
{
    font-size: 13px !important;
}
.main .form_wrapper .tab
{
    height: 45px  !important;
}
.login_tab i
{
    padding-left: 10px !important;
}
.main .form_wrapper .tab1
{
    padding: 0px 12px !important;
}
}
@media screen and (max-width:991px) {
    .full_img
    {
        display: none !important;
    }
}

button.login_google {
    border: none !important;
    box-shadow: 0 0 8px rgb(0 0 0 / 25%) !important;
    border-radius: 7px !important;
}
button.SignUp_google {
    border: none !important;
    box-shadow: 0 0 8px rgb(0 0 0 / 25%) !important;
    padding:1px 10px;
}

.login_google div
{
border-radius: 7px !important;
}
a.forgot_pass.sign_forgot_pass {
    font-size: 13px;
    font-weight: 500 !important;
}

@media screen and (max-width:426px) {
     .main .form_wrapper .tab.login_tab{ 
        width: 46% !important;
    }
}

@media screen and (min-width:426px) {
    .main .form_wrapper .tab.login_tab
    {
        width: 47%;
        display: flex;
        justify-content: space-between;
    }
}

.loginpage_side_register
{
    background-color: #ffffff;
    padding: 10px 25px;
    border-radius: 7px;
    outline: none;
    border: 1px solid rgb(83, 25, 168);
    color: rgb(83, 25, 168);
    font-weight: bold;
}

.MuiInputBase-root{
    border-radius: 10px !important;
    height: auto !important;
    border: #2C0264;
    border:1px solid  #2C0264 !important;
  }

  .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0.5px 4px 0.5px 15px !important;
}

.custom-autocomplete .MuiAutocomplete-root {
    /* Add your custom styles here */
    border: 1px solid red; /* Change the border color to your desired color */
  }
  
  .autocompleteInput {
    border: 'none', 
  }