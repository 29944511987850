.keypad {
  flex: 1.3;
  background-color: var(--bg-color-2);
  padding: 20px;
  display: flex;
}

.keypad p {
  line-height: 1.875rem;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms;
  user-select: none;
  cursor: pointer;
  color: var(--text-color-2);
}

.keypad_keys p:active {
  background-color: rgba(0, 0, 0, 0.14);
}

.keypad_keys {
  flex: 3;
  display: flex;
  flex-wrap: wrap;
}

.keypad_keys p {
  width: 33%;
  height: 25%;
}

.keypad_symbols {
  flex: 1;
}

.keypad_symbols p {
  color: #89a5f1;
  width: 100%;
  height: 20%;
}
